exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-de-bi-data-trends-js": () => import("./../../../src/pages/de-de/bi/data-trends.js" /* webpackChunkName: "component---src-pages-de-de-bi-data-trends-js" */),
  "component---src-pages-es-es-bi-data-trends-js": () => import("./../../../src/pages/es-es/bi/data-trends.js" /* webpackChunkName: "component---src-pages-es-es-bi-data-trends-js" */),
  "component---src-pages-fr-fr-bi-data-trends-js": () => import("./../../../src/pages/fr-fr/bi/data-trends.js" /* webpackChunkName: "component---src-pages-fr-fr-bi-data-trends-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-it-bi-data-trends-js": () => import("./../../../src/pages/it-it/bi/data-trends.js" /* webpackChunkName: "component---src-pages-it-it-bi-data-trends-js" */),
  "component---src-pages-ja-jp-bi-data-trends-js": () => import("./../../../src/pages/ja-jp/bi/data-trends.js" /* webpackChunkName: "component---src-pages-ja-jp-bi-data-trends-js" */),
  "component---src-pages-preview-[page-slug]-js": () => import("./../../../src/pages/preview/[page-slug].js" /* webpackChunkName: "component---src-pages-preview-[page-slug]-js" */),
  "component---src-pages-pt-br-bi-data-trends-js": () => import("./../../../src/pages/pt-br/bi/data-trends.js" /* webpackChunkName: "component---src-pages-pt-br-bi-data-trends-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-us-bi-data-trends-js": () => import("./../../../src/pages/us/bi/data-trends.js" /* webpackChunkName: "component---src-pages-us-bi-data-trends-js" */),
  "component---src-pages-us-search-js": () => import("./../../../src/pages/us/search.js" /* webpackChunkName: "component---src-pages-us-search-js" */),
  "component---src-templates-author-author-js": () => import("./../../../src/templates/author/author.js" /* webpackChunkName: "component---src-templates-author-author-js" */),
  "component---src-templates-blog-blog-js": () => import("./../../../src/templates/blog/blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-news-news-js": () => import("./../../../src/templates/news/news.js" /* webpackChunkName: "component---src-templates-news-news-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/page/page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-resource-form-success-js": () => import("./../../../src/templates/resource/form-success.js" /* webpackChunkName: "component---src-templates-resource-form-success-js" */),
  "component---src-templates-resource-resource-js": () => import("./../../../src/templates/resource/resource.js" /* webpackChunkName: "component---src-templates-resource-resource-js" */),
  "component---src-templates-term-term-js": () => import("./../../../src/templates/term/term.js" /* webpackChunkName: "component---src-templates-term-term-js" */)
}

