/**
 *  A config object that maps langcode to language info for all enabled languages on the site, e.g.
 *
 *   {
 *     en: { langName: "English", enLangName: "English", langCode: "en", pathPrefix: "", locale: "en-US" },
 *     fr: { langName: "Français", enLangName: "French", langCode: "fr", pathPrefix: "/fr", locale: "fr-FR" },
 *     ja: { langName: "日本語", enLangName: "Japanese", langCode: "ja", pathPrefix: "/jp", locale: "ja-JP" },
 *   }
 *
 */
const i18nConfig = JSON.parse(process.env.GATSBY_CONFIG_I18N);

/**
 * Return an object with some helper info for handling i18n. Generally this function should
 * be avoided and the I18nContext should be used instead (getI18nHelper is called in the page
 * template with the appropriate path and contentful locale, and passed to the context).
 *
 * @param contentfulLocale
 *   "Locale" coming from contentful, not necessarily ISO 3166 compliant.
 */
const createI18nHelper = (contentfulLocale = "en") => {
    // Some base i18n helper functionality.
    const i18nHelper = {
        languages: Object.keys(i18nConfig),
        map: i18nConfig,
    };

    // Chainable lang info getter, e.g. i18n.get("langName").from("pathPrefix", "/jp") would return "日本語"
    i18nHelper.get = (getProperty) => ({
        from: (fromProperty, fromValue) =>
            Object.values(i18nHelper.map).find((el) => el[fromProperty] === fromValue)?.[getProperty],
    });

    // Will return a localized date string, according to langName (ie: "Français")
    i18nHelper.getLocalisedDate = (dateStrStart, dateStrEnd = null, langName) => {
        if (!dateStrStart) return null;

        const dateOpts = { month: "long", day: "numeric", year: "numeric", timeZone: "UTC" };
        const startDate = new Date(dateStrStart).toLocaleDateString(
            i18nHelper.get("locale").from("langName", langName),
            dateOpts
        );
        let endDate = "";
        if (dateStrEnd) {
            endDate = ` - ${new Date(dateStrEnd).toLocaleDateString(
                i18nHelper.get("locale").from("langName", langName),
                dateOpts
            )}`;
        }

        return startDate + endDate;
    };

    // Grab the value from the map above that corresponds to the *current* locale.
    i18nHelper.curr = i18nHelper.map[contentfulLocale];

    // Method for determining whether a path already has a language prefix.
    i18nHelper.pathHasLangPrefix = (path) => {
        const langPrefixes = {
            ...Object.values(i18nHelper.map)
                .map((el) => el.pathPrefix)
                .filter((el) => el),
        };

        const result = Object.values(langPrefixes).filter((prefix) => {
            return prefix === path || path.startsWith(`${prefix}/`);
        });
        return Boolean(result.length);
    };

    return i18nHelper;
};

module.exports.createI18nHelper = createI18nHelper;
module.exports.i18nConfig = i18nConfig;

// @todo: note to self, remove this when rebasing the localization header PR.
module.exports.langMap = Object.fromEntries(
    Object.entries(createI18nHelper("en").map).map(([lang, el]) => [lang, el.enLangName])
);
