/**
 * This file contains imports and exports for Gatsby's browser APIs.
 * It includes global styles, third-party libraries, and context providers.
 */

// Importing React and global styles
import React from "react";
import "./src/styles/global.css";

// Importing third-party libraries
import "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import gaRouteUpdate from "@qlik/utils/gaRouteUpdate";
import { I18nProvider } from "@qlik/utils/i18nContext";
import adaCheckExclusions from "@qlik/utils/adaCheckExclusions";

// Importing context provider from the src context directory
import { AppProvider } from "@qlik/qlik/src/context/AppContext";

/**
 * wrapRootElement is a Gatsby browser API that wraps the root element with a context provider.
 * This enables the use of a React context throughout the application.
 *
 * @param {object} { element } - The root React element.
 * @returns The root element wrapped with the AppProvider component.
 */
export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;

// export const wrapPageElement = ({ element, props }) => {
//     if (props.pageContext.translatedPaths) {
//         return (
//             <I18nProvider translatedPaths={props.pageContext.translatedPaths} locale={props.pageContext.locale}>
//                 {element}
//             </I18nProvider>
//         );
//     } else {
//         // it's a 404 (which has it's own provider instance). So return the element
//         return element;
//     }
// };

export const onRouteUpdate = ({ location }) => {
    // Send Pageviews to GA
    gaRouteUpdate(window, location);

    // Function which hides or shows the Ada button element based on page exclusions in Contentful
    adaCheckExclusions(location);
};

// Fires when scroll position should be updated (including jump-links), and on any Gatsby-Link based navigation.
export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition, prevRouterProps }) => {
    // Get saved position - this may be [0, 0]
    const [savedX, savedY] = getSavedScrollPosition(routerProps.location);

    // Immediately try to scroll to the hash if it exists on initial load
    if (routerProps.location.hash) {
        const element = document.querySelector(routerProps.location.hash);
        if (element) {
            requestAnimationFrame(() => {
                element.scrollIntoView({ behavior: "smooth" });
            });
            return false;
        }
    } else {
        if (prevRouterProps) {
            // Scroll to saved position.
            requestAnimationFrame(() => {
                window.scrollTo(savedX, savedY);
            });
            return false;
        }
    }

    // If there is no saved scroll position, scroll to the top of the page
    if (savedX === 0 && savedY === 0) {
        requestAnimationFrame(() => {
            window.scrollTo(0, 0);
        });
        return true;
    }

    if (prevRouterProps) {
        // Scroll to saved position.
        requestAnimationFrame(() => {
            window.scrollTo(savedX, savedY);
        });
        return false;
    }
};
