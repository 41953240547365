import axios from "axios";

const handleAdaExclusions = (response) => {
    // Create a new <style> element to hide the Ada button
    const adaEntryStyle = document.createElement("style");
    // Add ID to the style element to make it easier to target
    adaEntryStyle.id = "adaEntryStyle";
    adaEntryStyle.innerHTML = `#ada-entry { opacity: 0 !important; } `;

    // Define the Ada entry element
    const adaEntryEl = document.getElementById("ada-entry");

    // Check if the Ada entry element exists, and that there are items in the response
    if (response?.data?.items && response?.data?.items.length > 0) {
        // Convert Content to object from JSON
        const adaChatExclusions = JSON.parse(response.data.items).excluded_paths;

        // When there are URLs to exclude Ada from
        if (adaChatExclusions.length > 0) {
            // If the current page URL is in the exclusion list
            if (adaChatExclusions.includes(window.location.pathname)) {
                // Check if Ada is instantiated
                if (window?.adaEmbed) {
                    // Check if Ada is open or closed
                    window.adaEmbed?.getInfo().then((adaState) => {
                        // If the chat is closed, hide the Ada button
                        if (adaState.isChatOpen === false && adaEntryEl) {
                            // Immediately hide the Ada button
                            adaEntryEl.style.transition = null;
                            adaEntryEl.style.opacity = "0";
                        }
                    });
                } else {
                    // Add the Ada entry style element to the head
                    document.head.appendChild(adaEntryStyle);
                }
            } else {
                // Add timeout for visibility, in case the intro text is still present from the previous context
                // When the page is not excluded, ensure the the Ada button is visible
                setTimeout(() => {
                    // Fade the Ada element in
                    if (adaEntryEl) {
                        adaEntryEl.style.transition = "opacity 0.3s ease-in-out";
                        adaEntryEl.style.opacity = null;
                    }

                    // Remove the Ada entry style element from the head
                    if (document.getElementById("adaEntryStyle")) {
                        // Target via ID instead of class name
                        document.getElementById("adaEntryStyle").remove();
                    }
                }, 1000); // 1 second seems sufficient in the case of Ada's context switching
            }
        }
    }
};

// Function to evaluate whether Ada should be shown or hidden
// Adds a new <style> element to hide the Ada button in order to cover direct-navigation to an excluded page
// Otherwise will hide/show via inline CSS on the ada-entry element
const adaCheckExclusions = (location) => {
    // Array to hold the Ada exclusions
    let adaChatExclusions = [];

    // Use axios to fetch adaChatExclusions
    axios
        .get(location.origin + "/adaChatExclusions-1.json")
        .then((response) => {
            return handleAdaExclusions(response);
        })
        .catch((error) => {
            console.error(error);
        });
};

export default adaCheckExclusions;
